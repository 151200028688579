import { DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';
import TogglesService, { DevelopmentToggles } from 'src/services/TogglesService';

type getEvincedAppsMenuOptionsHelperArgs = {
	hasMfaLicense: boolean;
	hasMsdkLicense: boolean;
	hasSiteScannerLicense: boolean;
	hasWebSdkLicense: boolean;
	hasPlatformLicense: boolean;
};

export const getEvincedAppsDropdownMenuOptions = (
	licenses: getEvincedAppsMenuOptionsHelperArgs
): IDropdownMenuOption[] => {
	const {
		hasMfaLicense,
		hasMsdkLicense,
		hasSiteScannerLicense,
		hasWebSdkLicense,
		hasPlatformLicense
	} = licenses;
	const options: IDropdownMenuOption[] = [];

	const isMFADashboard = window.location.href.includes(process.env.MFA_DASHBOARD_URL);
	const isMobileSDK = window.location.href.includes(process.env.MOBILE_SDK_DASHBOARD_URL);
	const isSiteScanner = window.location.href.includes(process.env.SITE_SCANNER_URL);
	const isProductHub = window.location.href.includes(process.env.PRODUCT_HUB_URL);

	const defaultProps = {
		type: DropdownOptionType.LINK,
		hasBottomBorder: false,
		openInNewTab: true
	};

	const enableSync = TogglesService.getToggle(DevelopmentToggles.ENABLE_SYNC);

	// TODO: refactor to loop through array of apps
	// each app will have a license flag and the rest of the props

	if (hasMfaLicense) {
		options.push({
			id: 'mfa-dashboard-url',
			url: `${process.env.MFA_DASHBOARD_URL}`,
			title: 'Mobile Flow Analyzer',
			active: isMFADashboard,
			...defaultProps
		});
	}
	if (hasPlatformLicense && enableSync) {
		options.push({
			id: 'wfa-dashboard-url',
			url: `${process.env.WFA_DASHBOARD_URL}`,
			title: 'Web Flow Analyzer',
			active: false,
			...defaultProps
		});
	}
	if (hasWebSdkLicense) {
		options.push({
			id: 'web-sdk-dashboard-url',
			url: `${process.env.WEB_SDK}`,
			title: 'Web SDK',
			active: false,
			...defaultProps
		});
	}
	if (hasMsdkLicense) {
		options.push({
			id: 'mobile-sdk-dashboard-url',
			url: `${process.env.MOBILE_SDK_DASHBOARD_URL}`,
			title: 'Mobile SDKs',
			active: isMobileSDK,
			...defaultProps
		});
	}
	if (hasSiteScannerLicense) {
		options.push({
			id: 'site-scanner-url',
			url: `${process.env.SITE_SCANNER_URL}`,
			title: 'Site Scanner',
			active: isSiteScanner,
			...defaultProps
		});
	}
	options.push({
		id: 'product-hub',
		url: `${process.env.PRODUCT_HUB_URL}`,
		title: 'Product Hub',
		active: isProductHub,
		...defaultProps
	});
	return options;
};
