import UserConfigurationService from './UserConfigurationService';

export enum DevelopmentToggles {
	PROPERTY_JSON_EDITOR = 'PROPERTY_JSON_EDITOR',
	SHOW_SCAN_URLSET_JSON_CONFIG = 'SHOW_SCAN_URLSET_JSON_CONFIG',
	SHOW_URLSET_JSON_CONFIG = 'SHOW_URLSET_JSON_CONFIG',
	SHOW_PRINT_BUTTON = 'SHOW_PRINT_BUTTON',
	SHOW_CRAWL_FILE_TYPES = 'SHOW_CRAWL_FILE_TYPES',
	SHOW_COMPARE_SCANS = 'SHOW_COMPARE_SCANS',
	ENABLE_AZURE_INTEGRATION = 'ENABLE_AZURE_INTEGRATION',
	SHOW_COOKIES_CONSENT_SELECTOR = 'SHOW_COOKIES_CONSENT_SELECTOR',
	SEND_USAGE_ANALYTICS = 'SEND_USAGE_ANALYTICS',
	ENABLE_SYNC = 'ENABLE_SYNC'
}

const getToggle = (toggle: DevelopmentToggles, includeUserToggles = true): boolean => {
	const value = localStorage.getItem(toggle);
	// cast the string value to it's boolean equivilant
	const devToggle = value === 'true';
	return devToggle || (includeUserToggles && UserConfigurationService.getUserToggles()[toggle]);
};

const setToggle = (toggle: DevelopmentToggles, value: string): void => {
	localStorage.setItem(toggle, value);
};

const removeToggle = (toggle: DevelopmentToggles): void => {
	localStorage.removeItem(toggle);
};

export default { getToggle, setToggle, removeToggle };
